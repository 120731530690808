




















import PageTitle from '@/components/PageTitle.vue'
import { DistributionService } from '@/includes/services/DistributionService'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import DistributionsList from 'piramis-base-components/src/components/Distributions/DistributionsList/DistributionsList.vue'
import { DistributionModel, DistributionState } from 'piramis-base-components/src/components/Distributions/types'

@Component({
  components: {
    PageTitle,
    DistributionsList,
  },
})
export default class DistributionsListPage extends Vue {
  isLoading = false

  goToDistribution(distributionId?: string): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        id: this.$route.params.id,
        ...distributionId ? { distributionId } : null,
        actionType: BaseItemViewAction.Watch,
      },
    })
  }

  gotoCreateDistribution(): void {
    this.$router.push({
      name: 'Distribution',
      params: {
        id: this.$route.params.id,
        actionType: BaseItemViewAction.New,
      },
    })
  }

  async getDistributions(state: DistributionState | null, offset: number): Promise<Array<DistributionModel>> {
    this.isLoading = true

    return new Promise(resolve => {
      DistributionService.getDistributions('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        limit: 10,
        offset,
        ...state !== null ? { state } : {}
      }).then(({ items }) => {
        resolve(items)
      })
        .finally(() => this.isLoading = false)
    })
  }

  async cancelDistribution(id: number):Promise<void> {
    this.isLoading = true

    return new Promise(resolve => {
      DistributionService.cancelDistribution('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        id,
      })
        .finally(() => {
          resolve()
          this.isLoading = false
        })
    })
  }
}
